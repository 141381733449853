
import moment from "moment";
import React, { useState, useEffect } from "react";
import "./ditetModal.scss";
import axios from '../../../axios';
import Close from '../../../images/light-close.svg'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TimePicker } from "antd";
import "antd/dist/antd.css";

const DitetModal = (props) => {
    const [isOpen, setOpen] = useState(true);
    const [orariFrom, setOrariFrom] = useState(props?.orariPrej?.slice(11, 16));
    const [orariTo, setOrariTo] = useState(props?.orariDeri?.slice(11, 16));
    const [pauzaFrom, setPauzaFrom] = useState(props?.pauzaPrej?.slice(11, 16));
    const [pauzaTo, setPauzaTo] = useState(props?.pauzaDeri?.slice(11, 16));
    const [checkedValue, setChangedValue] = useState(props?.online);
    const [disabled, setDisabled] = useState(false);
    const format = "HH:mm";

    useEffect(() => {
        props.getAllOret();
    }, [])

    const allowbooking = (e) => {
        setChangedValue(e.currentTarget.checked);
    };
    const OrariPrej = (e) => {
        setOrariFrom(e)
        setDisabled(true);
    };
    const OrariDeri = (e) => {
        setOrariTo(e)
        setDisabled(true);
    };
    const PauzaPrej = (e) => {
        setPauzaFrom(e)
        setDisabled(true);
    };
    const PauzaDeri = (e) => {
        setPauzaTo(e)
        setDisabled(true);
    };

    const handleHours = (e) => {
        e.preventDefault();
        let data = {
            DitaNr: props?.ditaNr,
            AllowBooking: checkedValue === true ? 1 : 0,
            AvailableFrom: orariFrom,
            AvailableTo: orariTo,
            PauzaFrom: pauzaFrom,
            PauzaTo: pauzaTo
        }
        if (props?.role === 10) {
            axios.put(`customer/appointments/editCustomerUserHoursDays/${props?.pid}`, data)
                .then(
                    data => {
                        toast.success('Të dhënat e anëtarit janë përditësuar me sukses!');
                        setOpen(false);
                        props.getAllOret();
                    }
                ).catch(err => {
                    toast.error('Të dhënat e anëtarit nuk janë përditësuar!');
                    console.log(err);
                });
        }
        if (props?.role === 2) {
            axios.put(`customerUser/appointments/editCustomerUserHoursDays/${props?.pid}`, data)
                .then(
                    data => {
                        toast.success('Të dhënat e anëtarit janë përditësuar me sukses!');
                        setOpen(false);
                        props.getAllOret();
                    }
                ).catch(err => {
                    toast.error('Të dhënat e anëtarit nuk janë përditësuar!');
                    console.log(err);
                });
        }
        if (props?.role === 3) {
            axios.put(`receptionist/appointments/editCustomerUserHoursDays/${props?.pid}`, data)
                .then(
                    data => {
                        toast.success('Të dhënat e anëtarit janë përditësuar me sukses!');
                        setOpen(false);
                        props.getAllOret();
                    }
                ).catch(err => {
                    toast.error('Të dhënat e anëtarit nuk janë përditësuar!');
                    console.log(err);
                });
        }
    }

    if (isOpen) {
        return (
            <>
                <div className="wholePage" onClick={() => setOpen(false)}></div>
                <div className='ditetModal'>
                    <img src={Close} onClick={() => setOpen(false)} className='closeModal' />
                    <div className='addModal-wrapper'>
                        <div className="ditetPunes">
                            <div className="orariPunes-Form">
                                <form onSubmit={handleHours}>
                                    <div className="input-design text-left">
                                        <div className="row">
                                            <div className="col-xl-3 col-lg-6 col-12 text-dark">
                                                {props?.dita}
                                            </div>
                                            <div className="col-xl-3 col-lg-6 col-12 text-dark">
                                                Punuese
                                                <input type="checkbox" id={"NotificationWithSMS"} className="switch col-2 ml-3" onClick={allowbooking} defaultChecked={props?.online} />
                                            </div>
                                        </div>
                                        <div className="dita1 mb-3 mt-5">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-3 col-12 text-dark">
                                                    <p className="text-dark">Orari i punes prej</p> 
                                                    <TimePicker
                                                        className="modal-ant-picker form-control col-12 col-lg-12 mb-3 mr-2"
                                                        defaultValue={moment(`${orariFrom}`, format)}
                                                        format={format}
                                                        popupStyle={{ zIndex: "999999" }}
                                                        popupClassName={"selectDate"}
                                                        onChange={(e, i) => OrariPrej(i)}
                                                        minuteStep={5}
                                                    />
                                                </div>
                                                <div className=" col-lg-6 col-md-3 col-12 text-dark">
                                                    <p className="text-dark">Orari i punes deri</p>                                                   {/* <input type="time" name="name" max="23" min="00" id="name" className="form-control" onChange={OrariDeri} defaultValue={orariTo} /> */}
                                                    <TimePicker
                                                        className="modal-ant-picker form-control col-12 col-lg-12 mb-3 mr-2"
                                                        defaultValue={moment(`${orariTo}`, format)}
                                                        format={format}
                                                        popupStyle={{ zIndex: "999999" }}
                                                        popupClassName={"selectDate"}
                                                        onChange={(e, i) => OrariDeri(i)}
                                                        minuteStep={5}
                                                    />
                                                </div>
                                                <div className=" col-lg-6 col-md-3 col-12 text-dark">
                                                    <p className="text-dark">Pauza prej</p>
                                                    {/* <input type="time" name="name" max="23" min="00" id="name" className="form-control" onChange={PauzaPrej} defaultValue={pauzaFrom} /> */}
                                                    <TimePicker
                                                        className="modal-ant-picker form-control col-12 col-lg-12 mb-3 mr-2"
                                                        defaultValue={moment(`${pauzaFrom}`, format)}
                                                        format={format}
                                                        popupStyle={{ zIndex: "999999" }}
                                                        popupClassName={"selectDate"}
                                                        onChange={(e, i) => PauzaPrej(i)}
                                                        minuteStep={5}
                                                    />

                                                </div>
                                                <div className=" col-lg-6  col-md-3 col-12">
                                                    <p className="text-dark">Pauza deri</p>
                                                    {/* <input type="time" name="name" max="23" min="00" id="name" className="form-control col-12 col-lg-4 mb-3 mr-2" onChange={toTime} defaultValue={dataStartdatahr} /> */}
                                                    {/* <input type="time" name="name" max="23" min="00" id="name" className="form-control" onChange={PauzaDeri} defaultValue={pauzaTo} /> */}
                                                    <TimePicker
                                                        className="modal-ant-picker form-control col-12 col-lg-12 mb-3 mr-2"
                                                        defaultValue={moment(`${pauzaTo}`, format)}
                                                        format={format}
                                                        popupStyle={{ zIndex: "999999" }}
                                                        popupClassName={"selectDate"}
                                                        onChange={(e, i) => PauzaDeri(i)}
                                                        minuteStep={5}

                                                    />
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className=" text-center col-lg-12 col-12 ">
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btnLogin submit">Ruaj</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </form>
                            </div >

                        </div>
                    </div >
                </div>
            </>
        )
    } else return null

}

export default DitetModal;