import axios from "../../../axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./anuloTerminin.scss";
import Close from '../../../images/light-close.svg'
import { useNavigate, useParams } from "react-router";


function AnuloTerminin(props) {
    console.log('po hapna', props);
    const [isOpen, setOpen] = useState(true);
    const [isEdit, setEdit] = useState(false);
    const { pid } = useParams();


    // const cardNumber1 = (e) => {
    //     const ud = Object.assign({}, cardNumber);
    //     ud.card_number = e.target.value;

    // };


    console.log("carddd", props?.getCardNumber)

    const submitData = (e) => {
        e.preventDefault();

        axios
            // .put(`test/editCustomersHisMapping/${props?.setPid}`, cardNumber)
            // / cancelAppointmentByUser /: id /: cid /: cuid
            .put(`/user/terminet/cancelAppointmentByUser/${props?.id}/${props?.cid}/${props?.userID}`)
            .then((res) => {
                console.log("res", res?.data)
                setOpen(false)
                props?.getUpdatedData();
            })
            .catch((err) => {
                console.log("axiosErr", err);
            });
    };


    // console.log('dataObject', data);
    if (isOpen) {
        return (
            <>
                <div className="wholePage" onClick={() => setOpen(false)}></div>
                <div className='AnuloTerminin pt-3 pb-5'>
                    <div className='anuloTerminin-wrapper'>
                        <h5 className="text-danger text-center">A jeni të sigurt që dëshironi ta anuloni këtë termin?</h5>
                        <div className="anuloTermininContent p-0 mt-5">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <button className="col-12 button_delete_Po btn btn-danger" onClick={submitData} >Po</button>
                                </div>
                                <div className=" col-12 col-lg-6">
                                    <button className=" col-12 button_delete_Jo btn btn-primary" onClick={() => setOpen(false)}>Jo
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else return null

}


export default AnuloTerminin;