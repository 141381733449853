import React, { useEffect, useState } from "react";
import './vizitat.scss';
import axios from '../../../axios';
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import {
    loggMeIN,
    rmAuth,
    toggleMenu
} from "./../../../redux/Functions/actions";
import Search from "../Search/search";
import Loader from "../Loader/Loader";

const Vizitat = (props) => {
    const [loading, setLoading] = useState(true);
    const [appointments, setappointments] = useState([]);
    const [isPrevious, setPrevious] = useState(false);
    const [previousAppoinements, setpreviousAppoinements] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [filteredPrev, setFilteredPrev] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageNumber1, setPageNumber1] = useState(0);
    const [open, setOpen] = useState(false);
    const productsPerPage = 20;
    const pagesVisited = pageNumber * productsPerPage;
    const pagesVisited1 = pageNumber1 * productsPerPage;
    let displayFilteredData = [];
    const pageCount = Math.ceil(filtered.length / productsPerPage);
    const pageCountPrev = Math.ceil(filteredPrev.length / productsPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };
    const changePage1 = ({ selected }) => {
        setPageNumber1(selected);
    };

    const getData = () => {
        axios.get('user/userCards/visits').then(
            data => {
                console.log('appointments', data.data);
                setappointments(data.data);
                setFiltered(data?.data)
                 setLoading(false);
            }
        ).catch(err => { console.log('Errori', err) });
    }

    const getPreviousAppointments = () => {
        axios.get('/user/appointments/previous').then(
            data => {
                setpreviousAppoinements(data.data);
                setLoading(false);
                setFilteredPrev(data?.data)
            }
        ).catch(err => { console.log('Errori', err) });
    }

    let auth = window.localStorage.getItem('terminettoken');
    useEffect(() => {
        if (auth !== null || auth !== undefined) {
            getData();
            getPreviousAppointments();
        }
    }, []);

    if (loading === true) {
        return <Loader/> 
    } else
        return (
            <>
                <div className={open ? "fromTo" : "from2"} >
                    <div className="refresh"><i onClick={() => setOpen(!open)} /></div>
                    {isPrevious ? <Search data={previousAppoinements} filtered={setFilteredPrev} setPageNumber={setPageNumber1} /> : <Search data={appointments} filtered={setFiltered} setPageNumber={setPageNumber} />}
                </div>
                <div className='vizita' style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>
                    <h3 class="titujt">Vizitat</h3>
                        <div class="table-responsive "  >
                    <div className="tableResponsive" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>
                            <table class="table" >
                                <thead>
                                    <tr className="list-item-head-new">
                                        <th className="col-md-2 col-4 text-left tableContent">Institucioni</th>
                                        <th className="col-md-2 col-4 text-left tableContent">Mjeku</th>
                                        <th className="col-md-2 col-4 text-left  tableContent">Prej</th>
                                        <th className="col-md-2 col-4 text-left tableContent">Deri</th>
                                        <th className="col-md-2 col-2 text-left  tableContent">Vizita</th>
                                        <th className="col-md-2 col-4 text-left  tableContent">Vizita e Ardhshme</th>
                                        <th className="col-md-2 col-4 text-left  tableContent">Download</th>

                                    </tr>
                                </thead>
                                {appointments && appointments?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka shenime</h6></div>}
                                {appointments && appointments?.length > 0 && <>
                                    <tbody>
                                        {
                                            displayFilteredData = filtered?.slice
                                                (pagesVisited, pagesVisited + productsPerPage)?.map
                                                (filtered => {
                                                    let date = filtered.DateIn?.slice(0, 10);
                                                    let koha = filtered.DateIn?.slice(11, 16);
                                                    let dateOut = filtered.ToDate?.slice(0, 10);
                                                    let kohaE = filtered.ToDate?.slice(11, 16);
                                                    let nextVisit = filtered.NextVizit?.slice(0, 10);
                                                    return (
                                                        <tr className="list-item">
                                                            <td className="col-md-2 col-4 text-left tableContent">{filtered.CustomerName} </td>
                                                            <td className="col-md-2 col-4 text-left tableContent">{filtered.DocName != null ? filtered.DocName : " "}</td>
                                                            <td className="col-md-2 col-4 text-left tableContent"> {date}  </td>
                                                            <td className="col-md-2 col-4 text-left tableContent">{dateOut == null ? " " : <div>{dateOut}</div>} </td>
                                                            <td className="col-md-2 col-4 text-left tableContent">{filtered.VizitaNr}</td>
                                                            <td className="col-md-2 col-4 text-left tableContent">{nextVisit != null ? <div>{nextVisit}</div> : " "} </td>
                                                            <td className="col-md-2 col-4 tableContent">{filtered?.URL?.length !== 0 ? (<a href={filtered.URL} download><i className="fa fa-download downloadIcon"></i></a>) : " "}</td>
                                                        </tr>
                                                    )
                                                })
                                        }
                                    </tbody>
                                </>}
                            </table>
                        </div>
                        <div className="paginationn">
                            <div className="paginationWrapperPatien text-right">
                                {((appointments?.length >= productsPerPage) ?
                                    <ReactPaginate
                                        previousLabel={<i className='fa fa-angle-double-left' />}
                                        nextLabel={<i className='fa fa-angle-double-right' />}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    /> : ''
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
}
const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Vizitat);