

import React, { useEffect, useState } from "react";
import './shtoKartelen.scss';
import axios from '../../../axios';
import { render } from '@testing-library/react';
import ProductsModal from "./productsModal";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import {
    loggMeIN,
    rmAuth,
    toggleMenu
} from "../../../redux/Functions/actions";
import Search from "../Search/search";
import EditModalKartel from "./editKartelen";
import Loader from "../Loader/Loader";


const Shtokartelen = (props) => {
    const [addClinic, setAddClinic] = useState([]);
    const [data, setData] = useState();
    const [dataToEdit, setDataToEdit] = useState();
    const [peProvoj, setPeProvoj] = useState();
    const [filtered, setFiltered] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [pid, setPid] = useState()
    const productsPerPage = 20;
    const productsPerList = 50;
    const pagesVisited = pageNumber * productsPerPage;
    let displayFilteredData = [];
    const pageCount = Math.ceil(filtered.length / productsPerPage);
    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const getAllClinics = () => {
        axios
            .get(`user/userCards`)
            .then((response) => {
                console.log("setDoctors", response?.data);
                setAddClinic(response.data);
                setFiltered(response?.data)
                setPid(response?.data?.id)
                console.log("idd", response?.data)
                setLoading(false);
            })
            .catch((err) => console.log("error", err));
    };

    const getData = () => {
        axios.get("user/terminet/getCustomers")
            .then((response) => {
                console.log("Clinics", response.data);
                setData(response.data);
                setLoading(false);
            })
            .catch((err) => console.log("error", err));
    };
    const renderEditModal = (data) => {
        console.log('remderFnData', data);
        setIsEditOpen(true);
        setDataToEdit(data);
        console.log('shabanGashiData', data)
    }

    useEffect(() => {
        getAllClinics();
        getData();
    }, []);

    console.log('filtered?.CardNumber', filtered)
    if (loading === true) {
        return <Loader />
    } else
        return (
            <>
                <ProductsModal user_id={props?.user_id} getClinicsData={() => getAllClinics()} isOpen={isOpen} handleClose={() => setIsOpen(false)} />
                <EditModalKartel props={dataToEdit} user_id={props?.user_id} setPid={filtered?.id} getCardNumber={filtered?.CardNumber} getClinicsData={getAllClinics} getNameOfCard={filtered} isEditOpen={isEditOpen} handleEditClose={() => setIsEditOpen(false)} />
                <Search data={addClinic} filtered={setFiltered} setPageNumber={setPageNumber} />
                <div className='shtoKartelen' style={{ left: props?.menu ? '20%' : '0' }}>
                    <h3 class="titujt" >Kartela e klientit</h3>
                    <div class="table-responsive" >
                        <div className="tableResponsive" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>
                            <table class="table"  >
                                <thead>
                                    <tr className="list-item-head-new">
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Institucioni</th>
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Numri i karteles</th>
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Data e validimit</th>
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Validuar</th>
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Koment</th>
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Ndrysho</th>
                                    </tr>
                                </thead>
                                {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka shenime</h6></div>}
                                {filtered && filtered?.length > 0 && <>
                                    {displayFilteredData = filtered?.slice
                                        (pagesVisited, pagesVisited + productsPerPage)?.map
                                        (filtered => {
                                            return (
                                                <>
                                                    <tr className="list-item">
                                                        <td className="col-md-2 col-4 text-left pl-4 tableContent">{filtered.CustomerName} </td>
                                                        <td className="col-md-2 col-4 text-left pl-4 tableContent">{filtered?.CardNumber}</td>
                                                        {filtered?.ValidatedOn?.length > 0 ? <td className='col-md-2 col-4 text-left pl-4 tableContent text-start'>{(filtered?.ValidatedOn)?.slice(0, 10)}</td> : <td className='col-md-2 col-4 text-left pl-4 tableContent text-start'></td>}
                                                        {filtered?.Validated === false ? <td className='col-md-2 col-4 text-left pl-4 tableContent  pl-5 col-3 '><i className='fa fa-times' /></td> : <td className='col-md-2 col-4 text-left pl-4 tableContent pl-5 col-3'><i className='fa fa-check' /></td>}
                                                        <td className="col-md-2 col-4 text-left pl-4 tableContent">{filtered?.komente}</td>
                                                        <td className='col-md-2 col-4 text-left pl-4 tableContent  pl-5 col-3 '> {filtered?.Validated === false ? <div onClick={(e) => { e.preventDefault(); renderEditModal(filtered) }}><i className="fa fa-pencil-square-o iconCursor" /></div> : " "}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}
                                </>
                                }
                            </table>
                        </div>
                        <div className="paginationn">
                            <div className="paginationWrapperPatien text-right">
                                {(addClinic?.length >= productsPerPage) ?
                                    <ReactPaginate
                                        previousLabel={<i className='fa fa-angle-double-left' />}
                                        nextLabel={<i className='fa fa-angle-double-right' />}
                                        pageCount={pageCount}
                                        onPageChange={changePage}
                                        containerClassName={"paginationBttns"}
                                        previousLinkClassName={"previousBttn"}
                                        nextLinkClassName={"nextBttn"}
                                        disabledClassName={"paginationDisabled"}
                                        activeClassName={"paginationActive"}
                                    /> : ''
                                }
                            </div>
                        </div>
                        <button className='addButton' onClick={() => setIsOpen(true)} >+</button>
                    </div>
                </div>
            </>
        )
}
const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Shtokartelen);

