import axios from "../../../axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./addCalendar.scss";
import Close from '../../../images/light-close.svg'

// import "./shtoKartelen.scss"
import moment from "moment";
import { connect } from 'react-redux';
import {
    loggMeIN,
    rmAuth,
    toggleMenu,
    setCustomerId

} from "./../../../redux/Functions/actions";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { render } from '@testing-library/react';
import ModalPortal from "../../../portal/ModalPortal";


const AddCalendar = ({ doctorId, dataWeek, costumerId, getData, getDataRole, getweekCall, user_id, role, dataToAdd, isAddOpen, handleAddClose }) => {
    console.log("addPros", doctorId)
    const [isOpen, setOpen] = useState(true);
    const [addClinic, setAddClinic] = useState([]);
    const [doctors, setDoctors] = useState()
    const [userData, setUserData] = useState();
    const [userIdCalendar, setUserIdCalendar] = useState(0);
    const [docName, setDocName] = useState();
    const [showLiveSearch, setLiveSearch] = useState();
    const [search, setSearch] = useState(false);


    let dataStart = moment(dataToAdd?.start).format("YYYY-MM-DD");
    console.log("dataStart", dataStart);
    let dataEnd = moment(dataToAdd?.end).format("YYYY-MM-DD");
    let dataStartdata = dataToAdd?.start.toISOString().slice(0, 10);
    // console.log("Sban", String(dataToAdd?.start.getHours()).padStart(2, '0') + ":" + String(dataToAdd?.start?.getMinutes()).padStart(2, '0') + ":" + String(dataToAdd?.start?.getSeconds()).padStart(2, '0'))
    const starttt = String(dataToAdd?.start.getHours()).padStart(2, '0') + ":" + String(dataToAdd?.start?.getMinutes()).padStart(2, '0') + ":" + String(dataToAdd?.start?.getSeconds()).padStart(2, '0');
    const endii = String(dataToAdd?.end.getHours()).padStart(2, '0') + ":" + String(dataToAdd?.end?.getMinutes()).padStart(2, '0') + ":" + String(dataToAdd?.end?.getSeconds()).padStart(2, '0');
    console.log("starttt", starttt);
    console.log("endii", endii);
    const [name, setName] = useState(null);
    const [surname, setSurname] = useState(null);
    const [email, setEmail] = useState(null);

    let dataStartt = dataStart + "T" + starttt + ".000Z"
    console.log("sttt", dataStartt)
    let dataEndd = dataEnd + "T" + endii + ".000Z"
    let dataStartdatahr = dataStartt.slice(11, 16);
    let dataendhr = dataEndd.slice(11, 16);
    useEffect(() => {
        setTimeout(() => {

            const ud = Object.assign({}, userData);
            ud.fromDate = dataStartt;
            ud.toDate = dataEndd;
            setUserData(ud);

            setDocName(doctorId)
        }, 1000);
    }, [dataEndd])
    // console.log("userData", moment(userData?.fromDate).format("HH:mm"))

    const toDate = (e) => {
        const ud = Object.assign({}, userData);
        ud.toDate = e.target.value;
        setUserData(ud);
    };
    const Pershkrimi = (e) => {
        const ud = Object.assign({}, userData);
        ud.Pershkrimi = e.target.value;
        setUserData(ud);
    };
    const UserFullName = (e) => {
        const ud = Object.assign({}, userData);
        ud.UserFullName = e.target.value;
        setUserData(ud);
    };

    const handleDocName = (e) => {
        setDocName(e.target.value)
    };
    const handleName = (e) => {
        if (e.target.value === "") {
            setName(null);
        } else {
            setName(e.target.value)
        }

    };
    const handleSurname = (e) => {
        if (e.target.value === "") {
            setSurname(null);
        } else {
            setSurname(e.target.value)
        }
    };
    const handleEmail = (e) => {
        if (e.target.value === "") {
            setEmail(null);
        } else {
            setEmail(e.target.value)
        }
    };
    const handlePatient = (e) => {
        setUserIdCalendar(e.target.value);
    };

    // console.log("docname", docName)
    const getDoctors = () => {
        // .get(`/test/getDoctorsByCustomer/${costumerId}/${role}`)
        if (role === 10) {
            axios
                .get(`/customer/appointments/getDoctorsByCustomer/${role}`)

                .then((data) => {
                    setDoctors(data?.data);
                })
                .catch((err) => console.log(err));
        }
        else if (role === 2) {
            axios
                .get(`/customerUser/appointments/getDoctorsByCustomer/${role}`)

                .then((data) => {
                    setDoctors(data?.data);
                })
                .catch((err) => console.log(err));
        }
        else if (role === 3) {
            axios
                .get(`/receptionist/appointments/getDoctorsByCustomer/${role}`)

                .then((data) => {
                    setDoctors(data?.data);
                })
                .catch((err) => console.log(err));
        }
    }
    const handleSearch = () => {
        setSearch(true);
        if (role === 10) {
            axios.get(`customer/appointments/findCostumerClients/${name}/${surname}/${email}`).then(
                data => {
                    console.log('appointments', data.data);
                    setLiveSearch(data.data);
                }
            ).catch(err => { console.log('Errori', err) });
        }
        else if (role === 2) {
            axios.get(`customerUser/appointments/findCostumerClients/${name}/${surname}/${email}`).then(
                data => {
                    console.log('appointments', data.data);
                    setLiveSearch(data.data);
                }
            ).catch(err => { console.log('Errori', err) });
        }
        if (role === 3) {
            axios.get(`receptionist/appointments/findCostumerClients/${name}/${surname}/${email}`).then(
                data => {
                    console.log('appointments', data.data);
                    setLiveSearch(data.data);
                }
            ).catch(err => { console.log('Errori', err) });
        }

    }

    useEffect(() => {
        getDoctors();
    }, [])





    // console.log("search", props)
    const submitData = (e) => {
        e.preventDefault();
        if (role === 10) {
            // alert("roli 10")
            axios
                .post(`customer/appointments/doctor/${doctorId}/${userIdCalendar}`, { ...userData, currentUser: user_id })
                .then((res) => {
                    console.log("res", res)
                    if (res?.data?.response[0]?.inserted_id === 0) {
                        alert(res?.data?.response[0]?.Porosia);
                    }
                    else {
                        // alert("else")
                        if (dataWeek) {
                            // alert("brenda ifit")
                            getweekCall(doctorId, dataWeek);
                        } else {
                            // alert(docName)
                            getData(doctorId);
                            // alert("brenda else")


                        }
                    }

                    handleAddClose();
                })
                .catch((err) => {
                    console.log("axiosErr", err);
                });
        }
        else if (role === 2) {
            axios
                .post(`customerUser/appointments/doctor/${user_id}/${userIdCalendar}`, { ...userData, currentUser: user_id })
                .then((res) => {
                    console.log("res", res)
                    setOpen(false)
                    if (dataWeek) {

                        getweekCall(docName, dataWeek);
                    } else {
                        getData(docName);

                    }
                })
                .catch((err) => {
                    console.log("axiosErr", err);
                });
        }
        else if (role === 3) {
            axios
                .post(`receptionist/appointments/doctor/${docName}/${userIdCalendar}`, { ...userData, currentUser: user_id })
                .then((res) => {
                    console.log("res", res)
                    setOpen(false)
                    if (dataWeek) {

                        getweekCall(docName, dataWeek);
                    } else {
                        getData(docName);

                    }
                })
                .catch((err) => {
                    console.log("axiosErr", err);
                });
        }

    };



    console.log('dataObject', userIdCalendar);
    if (isAddOpen) {
        return (
            <ModalPortal wrapperId="AddCalendarModal">
                <div className="wholePage" onClick={() => handleAddClose()}></div>
                <div className='modalAddCalendar'>
                    <img src={Close} onClick={() => handleAddClose()} className='closeModal ' />
                    <div className="addModal-Calendar p-0 ">
                        <form onSubmit={submitData} >
                            <div className="form-input">
                                <p className="text-left"></p>
                                {role === 2 ? "" :
                                    <select className='selectCustomerModalAdd mb-2' onChange={handleDocName} required title="This field is required" >
                                        <option value="" selected hidden>Ju lutem zgjedhni</option>
                                        <option disabled>Ju lutem zgjedhni </option>
                                        {doctors?.map((el) => {
                                            return (
                                                <option
                                                    value={el.id}
                                                    selected={(
                                                        Number(doctorId) ===
                                                        el?.id && (role === 2 || role === 10))
                                                        ? "selected"
                                                        : false
                                                    } className="options">
                                                    {el?.docName}
                                                </option>
                                            );
                                        })}
                                    </select>}
                                <h6>Rezervuesi</h6>
                                <input type="text" name="name" id="name" className="form-control mb-3  mr-4" onChange={UserFullName} placeholder="Emri i rezervuesit" />
                                <h6>Kerko Rezervuesin: </h6>
                                <div className="row rezervuesi">
                                    <div className="col-lg-4 col-xl-3 col-md-12 col-12 rezervuesiCol">
                                        <input
                                            type="text"
                                            className="main-search form-control mb-3"
                                            placeholder="Emri"
                                            onChange={handleName}
                                        />
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-12  col-12 rezervuesiCol">
                                        <input
                                            type="text"
                                            className="main-search form-control mb-3"
                                            placeholder="Mbiemri"
                                            onChange={handleSurname}
                                        />
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-12 rezervuesiCol">
                                        <input
                                            type="text"
                                            className="main-search form-control mb-3"
                                            placeholder="Email"
                                            onChange={handleEmail}

                                        />
                                    </div>
                                    <div className="col-md-1 col-12">
                                        <h6 onClick={handleSearch} className="btn searchButton ">Kerko</h6>
                                    </div>
                                </div>

                                <select className={search === true ? 'selectCustomerModalAdd mb-2' : 'selectCustomerModalAdd  mb-2 d-none'} onChange={handlePatient} title="This field is required" >
                                    {/* <option value="" selected disabled></option> */}
                                    <option value="" selected hidden>Ju lutem zgjedhni Rezervuesin </option>
                                    <option disabled>Ju lutem zgjedhni Rezervuesin </option>
                                    {showLiveSearch?.map((el) => {
                                        return (
                                            <option
                                                value={el?.id}
                                                className="options">
                                                {el?.UserFullName}
                                            </option>
                                        );
                                    })}
                                </select>
                                <h6>Pershkrimi i Terminit</h6>

                                <input type="text" name="name" id="name" className="form-control mb-3" required placeholder="Pershkrim per terminin" onChange={Pershkrimi} />
                                <h6>Data e Terminit</h6>
                                <input type="date" name="name" id="name" className="form-control mb-3" defaultValue={dataStartdata} disabled />
                                <h6>Orari i terminit</h6>
                                <p>{dataStartdatahr + "-" + dataendhr}</p>
                            </div>
                            <button type="submit" className="button_Add_Calendar" >
                                Ruaj
                            </button>
                        </form>

                    </div>
                </div>
            </ModalPortal>
        )
    } else return null

}

export default AddCalendar;