import React from 'react'
import moment from 'moment'
import moment_timezone from 'moment-timezone'
import { Calendar, momentLocalizer, globalizeLocalizer, Views } from 'react-big-calendar'
import "./Calendar.scss"
import globalize from 'globalize'
import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
// import withDragAndDrop from '../../../src/addons/dragAndDrop'

import { connect } from 'react-redux';
import {
    loggMeIN,
    rmAuth,
    toggleMenu,
    setCustomerId, setRole, setExternalDB,

} from "./../../../redux/Functions/actions";
import { useMemo, useCallback, useState, useEffect } from 'react';
import axios from '../../../axios';
import { useParams, useNavigate } from 'react-router-dom';
import { render } from '@testing-library/react';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import AddCalendar from './addCalendar';
import EditCalendar from './editCalendar';
import Loader from "../Loader/Loader";
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import AddCalendarAppointment from './addCalendarAppointment'
import { setSyntheticTrailingComments } from 'typescript'
const DragAndDropCalendar = withDragAndDrop(Calendar);

const MyCalendar = (props) => {
    console.log("PropsUserID", props)
    const [myEvents, setEvents] = useState();
    const [doctors, setDoctors] = useState();
    const [hours, setHours] = useState();
    const [loading, setLoading] = useState(true);
    const [doctorid, setDoctorid] = useState();
    const [doctorid1, setDoctorid1] = useState();
    const [dataWeek, setDataWeek] = useState();
    const [dataFullName, setDataFullName] = useState();
    const [firstDayOfWeek, setFirstDayOfWeek] = useState(0);
    const [isEditOpen1, setIsEditOpen] = useState(false);
    const [isAddWithPlusOpen, setIsAddWithPlusOpen] = useState(false);
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [dataToEdit, setDataToEdit] = useState();
    const [idForEdit, setIdForEdit] = useState();
    const [dataToAdd, setDataToAdd] = useState();
    const [starti, setStart] = useState();
    const [endi, setEnd] = useState();
    const [dataToAddWithPlus, setDataToAddWithPlus] = useState();
    const [addCalendar, setAddCalendar] = useState(true);
    const [arrayPush, setArrayPush] = useState([]);

    const fromTime = "2022-09-01T" + hours?.AvailableFrom + ".000"
    // "17:30:00"
    const toTme = "2022-09-01T" + hours?.AvailableTo + ".000"
    const fromTimeDb = new Date(fromTime)
    const ToTimeDb = new Date(toTme)
    const localizer = momentLocalizer(moment);



    // const goToNext = () => {
    //     toolbar.onNavigate(DragAndDropCalendar.Navigate.NEXT);
    // };
    const onChangeHandler = (e) => {
        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index]
        const option = el.getAttribute('id');
        setDoctorid(option);
        console.log("doctorId", option);
    }

    const getDataRole = () => {
        if (props.role !== 10) {
            let dateThisWeek = new Date().toISOString();
            if (props?.role === 2) {
                axios
                    // .get(`appointment/getDoctorAppoinments/${did}/${props?.customer_id}`)
                    .get(`customerUser/appointments/customerUser/${props?.user_id}/${dateThisWeek}${firstDayOfWeek}`)
                    .then((data) => {
                        if (data?.data?.length === 0) {
                            setArrayPush();
                        } else {
                            setEvents(data?.data);
                            console.log("eventet", data?.data)
                        }
                        setLoading(false);

                    })
                    .catch((err) => console.log(err));
            }
        }
    }


    useEffect(() => {
        getDataRole();
    }, [])




    const getSingleDoctor = (did) => {
        let dateThisWeek = new Date().toISOString();
        // alert(did)
        if (props?.role === 10) {
            axios
                // .get(`appointment/getDoctorAppoinments/${did}/${props?.customer_id}`)
                .get(`customer/appointments/customerUser/${did}/${dateThisWeek}/${firstDayOfWeek}`)
                .then((data) => {
                    console.error('artonramadani', data.data);
                    if (data?.data?.length === 0) {
                        setArrayPush();
                    } else {
                        setEvents(data?.data);
                        console.log("eventet", data?.data)
                    }
                    setLoading(false);

                })
                .catch((err) => console.log(err));
        }
        else if (props?.role === 3) {
            axios
                // .get(`appointment/getDoctorAppoinments/${did}/${props?.customer_id}`)
                .get(`receptionist/appointments/customerUser/${did}/${dateThisWeek}/${firstDayOfWeek}`)
                .then((data) => {
                    if (data?.data?.length === 0) {
                        setArrayPush();
                    } else {
                        setEvents(data?.data);
                        console.log("eventet", data?.data)
                    }
                    setLoading(false);

                })
                .catch((err) => console.log(err));
        }

    }
    console.log("from_date", firstDayOfWeek)

    const weekCall = (did, e) => {
        // e.preventDefault();
        if (firstDayOfWeek === 1) {
            var dataWeek = moment(e).toISOString();

        } else if (firstDayOfWeek === 0) {

            const today = moment(e);
            var dataWeek = today.startOf('week').toISOString();
        }
        // const testData = new Date(from_date).toString();
        setDataWeek(dataWeek)

        if (props?.role === 10) {
            alert(dataWeek)
            axios
                // .get(`appointment/getDoctorAppoinments/${did}/${props?.customer_id}`)
                .get(`customer/appointments/customerUser/${did}/${dataWeek}/${firstDayOfWeek}`)
                .then((data) => {
                    console.log('leonitaNika', dataWeek)
                    if (data?.data?.length === 0) {
                        setArrayPush();

                    } else {
                        setEvents(data?.data);
                        console.log("eventet", data?.data)
                    }
                    setLoading(false);

                })
                .catch((err) => console.log(err));
        }
        if (props?.role === 2) {
            axios
                // .get(`appointment/getDoctorAppoinments/${did}/${props?.customer_id}`)
                .get(`customerUser/appointments/customerUser/${props?.user_id}/${dataWeek}/${firstDayOfWeek}`)
                .then((data) => {
                    if (data?.data?.length === 0) {
                        setArrayPush();
                    } else {
                        setEvents(data?.data);
                        console.log("eventet", data?.data)
                    }
                    setLoading(false);

                })
                .catch((err) => console.log(err));
        }
        if (props?.role === 3) {
            axios
                // .get(`appointment/getDoctorAppoinments/${did}/${props?.customer_id}`)
                .get(`receptionist/appointments/customerUser/${did}/${dataWeek}/${firstDayOfWeek}`)
                .then((data) => {
                    if (data?.data?.length === 0) {
                        setArrayPush();
                    } else {
                        setEvents(data?.data);
                        console.log("eventet", data?.data)
                    }
                    setLoading(false);

                })
                .catch((err) => console.log(err));
        }
    }
    const handleSelectDays = (e) => {
        if (e === Views.WEEK) {
            setFirstDayOfWeek(0)
        } else if (e === Views.DAY) {
            setFirstDayOfWeek(1)
        }

    }

    const handleSelectSlot = (e, doctorid, doctors, dataWeek, addCalendar) => {
        console.log('remderFnData', e, doctorid);
        setIsAddOpen(true);
        setDataToAdd(e, doctorid);
        // setDoctorid1(doctorid);
        // alert(doctorid);
        console.log('shabanGashiData', e?.start)
    }

    const handleSelectSlotAddAppointment = (e, doctorid, doctors, dataWeek, addCalendar) => {
        console.log('remderFnData', e, doctorid);
        setIsAddWithPlusOpen(true);
        setDataToAddWithPlus(e, doctorid);
        // setDoctorid1(doctorid);
        // alert(doctorid);
        console.log('shabanGashiData', e?.start)
    }

    console.log('startuese', starti)

    const handleSelectEvent = useCallback(
        ({ start, end, id }, doctorid, dataFullName, dataWeek) => {
            render(<EditCalendar start={start} end={end} doctorId={Number(doctorid)} role={props?.role} getDataRole={getDataRole} getData={getSingleDoctor} getweekCall={weekCall} dataWeek={dataWeek} costumerId={props?.customer_id} user_id={id} userID={props?.user_id} />);
        },
        []

        )
    // const handleSelectSlot = useCallback(
    //     ({ start, end }, doctorid, doctors, dataWeek, addCalendar) => {
    //         console.log("start", doctorid)
    //         render(<AddCalendar start={() => useCallback(({start})=>{return start}) } end={end} doctorId={doctorid} addCalendar={addCalendar} dataWeek={dataWeek} doctors={doctors} costumerId={props?.customer_id} getweekCall={weekCall} getDataRole={getDataRole} getData={getSingleDoctor} user_id={props?.user_id} role={props?.role} />);
    //     },

    //     []

    // )
    // const handleSelectSlotAddAppointment = useCallback(
    //     ({ start, end }, doctorid, doctors, dataWeek) => {
    //         render(<AddCalendarAppointment doctorId={doctorid} doctors={doctors} dataWeek={dataWeek} costumerId={props?.customer_id} getData={getSingleDoctor} getDataRole={getDataRole} getweekCall={weekCall} user_id={props?.user_id} role={props?.role} />);
    //     },
    //     []

    // )
    const moveEvent = useCallback(
        ({ event, start, end, id, isAllDay: droppedOnAllDaySlot = false }, doctorid) => {
            if (id < 0) {
                return droppedOnAllDaySlot = true;
            }
            else {

                let dataStartuese = String(start?.getFullYear()) + "-" + String(start?.getMonth() + 1).padStart(2, '0') + "-" + String(start?.getDate()).padStart(2, '0');
                let dataEndi = String(end?.getFullYear()) + "-" + String(end?.getMonth() + 1).padStart(2, '0') + "-" + String(end?.getDate()).padStart(2, '0');

                const starttt = String(start?.getHours()).padStart(2, '0') + ":" + String(start?.getMinutes()).padStart(2, '0') + ":" + String(start?.getSeconds()).padStart(2, '0');
                const endii = String(end?.getHours()).padStart(2, '0') + ":" + String(end?.getMinutes()).padStart(2, '0') + ":" + String(end?.getSeconds()).padStart(2, '0');
                let dataStartt = dataStartuese + "T" + starttt + ".000Z"
                let dataEndd = dataEndi + "T" + endii + ".000Z"
                let data = {
                    fromDate: dataStartt,
                    toDate: dataEndd,
                }
                const { allDay } = event
                if (!allDay && droppedOnAllDaySlot) {
                    event.allDay = true
                }

                setArrayPush((prev) => {
                    const existing = prev.find((ev) => ev.id === event.id) ?? {}
                    const filtered = prev.filter((ev) => ev.id !== event.id)
                    return [...filtered, { ...existing, start, end, allDay }]
                })

                if (props?.role === 10) {
                    axios
                        .put(`customer/appointments/doctorDrag/${event.id}/${doctorid}`, data)
                        .then((res) => {
                            console.log("res", res)
                            // getSingleDoctor();
                        })
                        .catch((err) => {
                            console.log("axiosErr", err);
                        });
                }
                if (props?.role === 2) {
                    axios
                        .put(`customerUser/appointments/doctorDrag/${event.id}/${props?.user_id}`, data)
                        .then((res) => {
                            console.log("res", res)
                            // getSingleDoctor();
                        })
                        .catch((err) => {
                            console.log("axiosErr", err);
                        });
                }
                if (props?.role === 3) {
                    axios
                        .put(`receptionist/appointments/doctorDrag/${event.id}/${doctorid}`, data)
                        .then((res) => {
                            console.log("res", res)
                            // getSingleDoctor();
                        })
                        .catch((err) => {
                            console.log("axiosErr", err);
                        });
                }
            }

        },

        [setArrayPush]
    )

    const resizeEvent = useCallback(
        ({ event, start, end }, doctorid) => {

            console.log("leo", String(start?.getFullYear()))
            console.log("leo", String(start?.getMonth() + 1).padStart(2, '0'))
            console.log("leo", String(start?.getDate()).padStart(2, '0'))
            let dataStartuese = String(start?.getFullYear()) + "-" + String(start?.getMonth() + 1).padStart(2, '0') + "-" + String(start?.getDate()).padStart(2, '0');
            let dataEndi = String(end?.getFullYear()) + "-" + String(end?.getMonth() + 1).padStart(2, '0') + "-" + String(end?.getDate()).padStart(2, '0');
            let dataStart = moment(start).format("YYYY-MM-DD");
            let dataEnd = moment(end).format("YYYY-MM-DD");
            console.log("dataStartuesr", dataStartuese, dataStart)
            console.log("Sban", String(start?.getHours()).padStart(2, '0') + ":" + String(start?.getMinutes()).padStart(2, '0') + ":" + String(start?.getSeconds()).padStart(2, '0'))
            const starttt = String(start?.getHours()).padStart(2, '0') + ":" + String(start?.getMinutes()).padStart(2, '0') + ":" + String(start?.getSeconds()).padStart(2, '0');
            const endii = String(end?.getHours()).padStart(2, '0') + ":" + String(end?.getMinutes()).padStart(2, '0') + ":" + String(end?.getSeconds()).padStart(2, '0');
            let dataStartt = dataStartuese + "T" + starttt + ".000Z"
            let dataEndd = dataEndi + "T" + endii + ".000Z"
            let data = {
                fromDate: dataStartt,
                toDate: dataEndd,
            }

            setArrayPush((prev) => {
                const existing = prev.find((ev) => ev.id === event.id) ?? {}
                const filtered = prev.filter((ev) => ev.id !== event.id)
                return [...filtered, { ...existing, start, end }]
            })
            if (props?.role === 10) {
                axios
                    .put(`customer/appointments/doctorDrag/${event.id}/${doctorid}`, data)
                    .then((res) => {
                        console.log("res", res)
                    })
                    .catch((err) => {
                        console.log("axiosErr", err);
                    });
            }

            else if (props?.role === 2) {
                axios
                    .put(`customerUser/appointments/doctorDrag/${event.id}/${doctorid}`, data)
                    .then((res) => {
                        console.log("res", res)
                    })
                    .catch((err) => {
                        console.log("axiosErr", err);
                    });
            }

            else if (props?.role === 3) {
                axios
                    .put(`receptionist/appointments/doctorDrag/${event.id}/${doctorid}`, data)
                    .then((res) => {
                        console.log("res", res)
                    })
                    .catch((err) => {
                        console.log("axiosErr", err);
                    });
            }
        }
        ,
        [setArrayPush]
    )


    console.log("eventsData", myEvents)

    const appointmets = () => {

        let dataArray = [];
        if (myEvents !== undefined || myEvents !== []) {

            myEvents?.map((evente) => {

                let StartDateTime = String((evente?.FromDate)?.replace("T", " ")?.replace('.000Z', ''));
                let EndDateTime = String((evente?.ToDate)?.replace("T", " ")?.replace('.000Z', ''));
                let startTime = moment(StartDateTime).toDate();
                let endTime = moment(EndDateTime).toDate();
                let data = {
                    getAppointment: getSingleDoctor,
                    getweekCall: weekCall,
                    id: evente.id,
                    title: evente?.Pershkrimi,
                    Ngjyra: evente.Ngjyra,

                    allDay: false,
                    start: startTime,
                    end: endTime,
                    status: 1,
                }
                dataArray.push(data);
                setArrayPush(dataArray);
                setDataFullName(data.id);

            })
        }

    }



    const getDoctors = () => {
        if (props?.role === 10) {
            axios
                .get(`/customer/appointments/getDoctorsByCustomer/${props?.role}`)
                .then((data) => {
                    setDoctors(data?.data);
                    setLoading(false);

                })
                .catch((err) => console.log(err));
        }

        else if (props?.role === 2) {
            axios
                .get(`/customerUser/appointments/getDoctorsByCustomer/${props?.role}`)
                .then((data) => {
                    setDoctors(data?.data);
                    setLoading(false);

                })
                .catch((err) => console.log(err));
        }
        else if (props?.role === 3) {
            axios
                .get(`/receptionist/appointments/getDoctorsByCustomer/${props?.role}`)
                .then((data) => {
                    setDoctors(data?.data);
                    setLoading(false);

                })
                .catch((err) => console.log(err));
        }
    }
    const getHours = () => {
        // .get(`/test/getDoctorsByCustomer/${props?.customer_id}/${props?.role}`)
        if (props?.role === 10) {
            axios
                .get(`/customer/appointments/getWorkingDaysHoursCompany`)
                .then((data) => {
                    setHours(data?.data[0]);
                    setLoading(false);

                })
                .catch((err) => console.log(err));
        }
        else if (props?.role === 2) {
            axios
                .get(`/customerUser/appointments/getWorkingDaysHoursCompany`)
                .then((data) => {
                    setHours(data?.data[0]);
                    setLoading(false);

                })
                .catch((err) => console.log(err));
        }
        else if (props?.role === 3) {
            axios
                .get(`/receptionist/appointments/getWorkingDaysHoursCompany`)
                .then((data) => {
                    setHours(data?.data[0]);
                    setLoading(false);

                })
                .catch((err) => console.log(err));
        }
    }

    useEffect(() => {

        if (props?.role === 2) {

            weekCall(doctorid);
        } else {
            getSingleDoctor(doctorid);

        }

    }, [doctorid])

    useEffect(() => {
        getHours();
        getDoctors();
    }, [])

    // useEffect(() => {
    // }, [doctorid])
    useEffect(() => {
        handleSelectDays(firstDayOfWeek)
    }, [firstDayOfWeek])




    useEffect(() => {
        if (doctorid === undefined) {
            return null
        } else {
            appointmets();
        }

    }, [myEvents])

    useEffect(() => {
        if (props?.role === 2) {
            appointmets();
        }

    }, [myEvents])
    console.log("starti", starti)
    if (loading === true) {
        return <Loader />
    } else
        return (
            <>
                <AddCalendar doctorId={doctorid} addCalendar={addCalendar} dataWeek={dataWeek} doctors1={doctors} costumerId={props?.customer_id} getweekCall={weekCall} getDataRole={getDataRole} getData={getSingleDoctor} user_id={props?.user_id} role={props?.role} dataToAdd={dataToAdd} isAddOpen={isAddOpen} handleAddClose={() => setIsAddOpen(false)} />
                <AddCalendarAppointment doctorId={doctorid} doctors={doctors} dataWeek={dataWeek} costumerId={props?.customer_id} getData={getSingleDoctor} getDataRole={getDataRole} getweekCall={weekCall} user_id={props?.user_id} role={props?.role} dataToAddWithPlus={dataToAddWithPlus} isAddWithPlusOpen={isAddWithPlusOpen} handleAddPlusClose={() => setIsAddWithPlusOpen(false)} />
                <div className="myCustomHeight" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>


                    <div className='titleTerminet' style={{ left: props?.menu ? '20%' : '0' }}>
                        <h3 className='titujt'>Terminet</h3>
                    </div>
                    {props?.role !== 2 ?
                        <select className='selectCustomerName' onChange={onChangeHandler} required title="This field is required" >
                            <option value="" selected hidden>Ju lutem zgjedhni</option>
                            <option disabled>Ju lutem zgjedhni </option>
                            {doctors?.map((el) => {

                                return (
                                    <option id={el.id} className="options" selected={props?.role === 2 ?
                                        Number(props?.user_id) ===
                                            el?.id
                                            ? "selected"
                                            : false
                                        : ""}>
                                        {el?.docName}
                                    </option>
                                );
                            })}
                        </select>
                        : ""
                    }
                    <div className="calendar">

                        <DragAndDropCalendar
                            eventPropGetter={(evente) => {
                                { console.log("eventetNgjyra", evente) }
                                let backgroundColor = evente.Ngjyra
                                return { style: { backgroundColor } }
                            }
                            }
                            localizer={localizer}
                            defaultView={Views.WEEK}
                            onNavigate={(e) => { weekCall(doctorid, e, dataWeek, firstDayOfWeek) }}
                            onView={(e) => handleSelectDays(e)}

                            views={[Views.WEEK, Views.DAY]}

                            messages={{
                                week: 'Kalendari Javor',
                                day: 'Kalendari Ditor',
                                next: [(firstDayOfWeek === 0 ? 'Java e ardhshme ' : "Dita e ardhshme")],
                                today: [(firstDayOfWeek === 0 ? 'Sot' : "Sot")],
                                previous: [(firstDayOfWeek === 0 ? 'Java e kaluar' : "Dita e kaluar")],

                            }}

                            events={arrayPush}
                            min={fromTimeDb}
                            max={ToTimeDb}
                            onSelectEvent={(e) => {

                                if (e.id > 0) {
                                    handleSelectEvent(e, doctorid, dataFullName, dataWeek)

                                } else {
                                    return null;
                                }
                            }
                            }
                            onEventDrop={(e) => {
                                if (e.event.id > 0) {


                                    moveEvent(e, doctorid)

                                } else {
                                    return null;
                                }
                            }
                            }
                            onSelectSlot={(e) => handleSelectSlot(e, doctorid, doctors, dataWeek, addCalendar)
                            }
                            onEventResize={(e) => {
                                if (e.event.id > 0) {


                                    resizeEvent(e, doctorid)

                                } else {
                                    return null;
                                }
                            }
                            }
                            startAccessor="start"
                            endAccessor="end"
                            step={hours?.TermineCdoMin}
                            timeslots={1}
                            selectable
                            showMultiDayTimes
                            resizable

                            // overlapping={false}
                            // popup

                            style={{ height: 650 }}
                        />
                    </div>
                </div>
                <div className='addNewAppointment' onClick={(e) => handleSelectSlotAddAppointment(e, doctorid, doctors, dataWeek, addCalendar)}>+</div>


            </>
        )

}



const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id,
        customer_id: state.data.customer_id,
        role: state.data.role,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu()),
        // loadingOff: () => dispatch(loadingOff()),
        setRole: () => dispatch(setRole()),
        setCustomerId: (customer_id) => dispatch(setCustomerId(customer_id)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyCalendar);