import axios from "../../../axios";
import React, { useState, useEffect } from "react";
import Close from '../../../images/light-close.svg'
import "./editCalendar.scss";
import moment from "moment";
import { useParams } from "react-router";
import { TimePicker } from "antd";
import "antd/dist/antd.css";

const EditCalendar = (props) => {
    console.log("hjithqka",props)
    const [isOpen, setOpen] = useState(true);
    const [deletModal, setOpenModal] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [status, setStatus] = useState();
    const [doctors, setDoctors] = useState()
    const [userID, setUserID] = useState()
    const [userData, setUserData] = useState();
    const [docName, setDocName] = useState(props?.doctorId);
    const [appointmentData, setAppointmentData] = useState();
    const [initDate, setInitDate] = useState(props.start.toISOString().slice(0, 10));
    const [initDateEE, setInitDateEE] = useState(props.end.toISOString().slice(0, 10));
    const [datatimestart, setDatatimestart] = useState(moment(props.start).format("HH:mm"));
    const [datatimeEnd, setDatatimeEnd] = useState(moment(props.end).format("HH:mm"));

    const { did } = useParams();
    const format = "HH:mm";

    let dataStartdata = initDate;
    let dataend = initDateEE;
    let dataStartdatahr = datatimestart;
    let dataendhr = datatimeEnd;
    let kosova = dataStartdata + "T" + dataStartdatahr + ":00.000Z"
    let shqip = dataend + "T" + dataendhr + ":00.000Z"

    useEffect(() => {
        const ud = Object.assign({}, userData);
        ud.fromDate = kosova;
        ud.toDate = shqip;
        setUserData(ud);
    }, [initDate, initDateEE, datatimestart, datatimeEnd])

    const toDate = (e) => {
        setInitDate(e.target.value)
        setInitDateEE(e.target.value)
        setDisabled(true);
    };
    const toTime = (e) => {
        setDatatimestart(e)
        setDisabled(true);
    };
    const toEnd = (e) => {
        setDatatimeEnd(e)
        setDisabled(true);
    };
    const Pershkrimi = (e) => {
        const ud = Object.assign({}, userData);
        ud.Pershkrimi = e.target.value;
        setUserData(ud);
        setDisabled(true);
    };
    const UserFullName = (e) => {
        const ud = Object.assign({}, userData);
        ud.UserFullName = e.target.value;
        setUserData(ud);
        setDisabled(true);
    };
    const handleStatus = (e) => {
        const ud = Object.assign({}, userData);
        ud.StatusID = Number(e.target.value);
        setUserData(ud);
        setDisabled(true);
    };
    const handleDocName = (e) => {
        const ud = Object.assign({}, userData);
        ud.DoctorID = props?.doctorId;
        setDocName(e.target.value)
        setDisabled(true);
        setUserData(ud);
    };

    const getDoctors = () => {
        if (props?.role === 10) {
            axios
                .get(`/customer/appointments/getDoctorsByCustomer/${props?.role}`)
                .then((data) => {
                    setDoctors(data?.data);
                })
                .catch((err) => console.log(err));
        }
        else if (props?.role === 2) {
            axios
                .get(`/customerUser/appointments/getDoctorsByCustomer/${props?.role}`)
                .then((data) => {
                    setDoctors(data?.data);
                })
                .catch((err) => console.log(err));
        }
        else if (props?.role === 3) {
            axios
                .get(`/receptionist/appointments/getDoctorsByCustomer/${props?.role}`)
                .then((data) => {
                    setDoctors(data?.data);
                })
                .catch((err) => console.log(err));
        }
    }

    const getStatus = () => {
        if (props?.role === 10) {
            axios
                .get(`customer/appointments/status`)
                .then((data) => {
                    setStatus(data?.data);
                    console.log("status", status)
                })
                .catch((err) => console.log(err));
        }
        else if (props?.role === 2) {
            axios
                .get(`customerUser/appointments/status`)
                .then((data) => {
                    setStatus(data?.data);
                    console.log("status", status)
                })
                .catch((err) => console.log(err));
        }
        else if (props?.role === 3) {
            axios
                .get(`receptionist/appointments/status`)
                .then((data) => {
                    setStatus(data?.data);
                    console.log("status", status)
                })
                .catch((err) => console.log(err));
        }
    }
    const deleteAppointment = (e) => {
        e.preventDefault();
        if (props?.role === 10) {
            axios
                .delete(`customer/appointments/${props?.user_id}/${docName}`)
                .then((data) => {
                    setDisabled(false)
                    setOpen(false)
                    if (props?.dataWeek) {
                        props?.getweekCall(docName, props?.dataWeek);
                    } else {
                        props?.getData(docName);
                    }
                })
                .catch((err) => console.log(err));
        }
        else if (props?.role === 2) {
            axios
                .delete(`customerUser/appointments/${props?.user_id}/${props?.userID}`)
                .then((data) => {
                    setDisabled(false)
                    setOpen(false)
                    if (props?.dataWeek) {
                        props?.getweekCall(props?.userID, props?.dataWeek);
                    } else {
                        props?.getData(props?.userID);
                    }
                })
                .catch((err) => console.log(err));
        }
        else if (props?.role === 3) {
            axios
                .delete(`receptionist/appointments/${props?.user_id}/${docName}`)
                .then((data) => {
                    setDisabled(false)
                    setOpen(false)
                    if (props?.dataWeek) {
                        props?.getweekCall(docName, props?.dataWeek);
                    } else {
                        props?.getData(docName);
                    }
                })
                .catch((err) => console.log(err));
        }
    }
    const getAppointmentData = () => {
        if (props?.role === 10) {
            axios
                .get(`customer/appointments/single/${props?.user_id}/${docName}`)
                .then((data) => {
                    setAppointmentData(data?.data[0]);
                    setUserID(data?.data[0]?.UserID);
                    console.log("UserID", userID)
                    const ud = Object.assign({}, userData);
                    ud.Pershkrimi = data?.data[0].Pershkrimi;
                    ud.UserFullName = data?.data[0].UserFullName;
                    ud.fromDate = data?.data[0].FromDate;
                    ud.toDate = data?.data[0].ToDate;
                    ud.StatusID = data?.data[0].StatusID;
                    ud.DoctorID = props?.doctorId;
                    setUserData(ud);
                })
                .catch((err) => console.log(err));
        }
        else if (props?.role === 2) {
            axios
                .get(`customerUser/appointments/single/${props?.user_id}/${props?.userID}`)
                .then((data) => {
                    setAppointmentData(data?.data[0]);
                    setUserID(data?.data[0]?.UserID);
                    console.log("UserID", userID)
                    const ud = Object.assign({}, userData);
                    ud.Pershkrimi = data?.data[0].Pershkrimi;
                    ud.UserFullName = data?.data[0].UserFullName;
                    ud.fromDate = data?.data[0].FromDate;
                    ud.toDate = data?.data[0].ToDate;
                    ud.StatusID = data?.data[0].StatusID;
                    ud.DoctorID = props?.doctorId;
                    setUserData(ud);
                })
                .catch((err) => console.log(err));
        }
        else if (props?.role === 3) {
            axios
                .get(`receptionist/appointments/single/${props?.user_id}/${docName}`)
                .then((data) => {
                    setAppointmentData(data?.data[0]);
                    setUserID(data?.data[0]?.UserID);
                    console.log("UserID", userID)
                    const ud = Object.assign({}, userData);
                    ud.Pershkrimi = data?.data[0].Pershkrimi;
                    ud.UserFullName = data?.data[0].UserFullName;
                    ud.fromDate = data?.data[0].FromDate;
                    ud.toDate = data?.data[0].ToDate;
                    ud.StatusID = data?.data[0].StatusID;
                    ud.DoctorID = props?.doctorId;
                    setUserData(ud);
                })
                .catch((err) => console.log(err));
        }
    }

    useEffect(() => {
        getAppointmentData();
        getStatus();
        getDoctors();
    }, [])

    const submitData = (e) => {
        e.preventDefault();
        if (props?.role === 10) {
            axios
                .put(`customer/appointments/doctor/${props?.user_id}/${docName}`, userData)
                .then((res) => {
                    console.log("res", res)
                    setDisabled(false)
                    setOpen(false)
                    if (props?.dataWeek) {

                        props?.getweekCall(docName, props?.dataWeek);
                    } else {
                        props?.getData(docName);

                    }
                })
                .catch((err) => {
                    console.log("axiosErr", err);
                });
        }
        else if (props?.role === 2) {
            axios
                .put(`customerUser/appointments/doctor/${props?.user_id}/${props?.userID}`, userData)
                .then((res) => {
                    console.log("res", res)
                    setDisabled(false)
                    setOpen(false)
                    if (props?.dataWeek) {

                        props?.getweekCall(docName, props?.dataWeek);
                    } else {
                        props?.getData(docName);

                    }
                })
                .catch((err) => {
                    console.log("axiosErr", err);
                });
        }
        else if (props?.role === 3) {
            axios
                .put(`receptionist/appointments/doctor/${props?.user_id}/${docName}`, userData)
                .then((res) => {
                    console.log("res", res)
                    setDisabled(false)
                    setOpen(false)
                    if (props?.dataWeek) {

                        props?.getweekCall(docName, props?.dataWeek);
                    } else {
                        props?.getData(docName);

                    }
                })
                .catch((err) => {
                    console.log("axiosErr", err);
                });
        }
    };

    if (isOpen) {
        return (
            <>
                <div className="wholePage" onClick={() => setOpen(false)}></div>
                <div className='formEdit'>
                    <img src={Close} onClick={() => setOpen(false)} className='closeModal ' />
                    <div className='editModal-Calendar'>
                        <div className="editCalendarWraper p-0 mt-5">
                            <form onSubmit={submitData} >
                                <div className="form-input">
                                    {props?.role === 2 ? "" :
                                        <select className='selectCustomerEditCalendar col-12 mb-2' onChange={handleDocName} required title="This field is required" >
                                            <option value="" selected hidden>Ju lutem zgjedhni</option>
                                            <option disabled>Ju lutem zgjedhni</option>
                                            {doctors?.map((el) => {
                                                return (
                                                    <option
                                                        value={el.id}
                                                        selected={
                                                            Number(props?.doctorId) ===
                                                                el?.id
                                                                ? "selected"
                                                                : false
                                                        } className="options">
                                                        {el?.docName}
                                                    </option>
                                                );
                                            })}
                                        </select>}
                                    <h6>Statusi i Terminit</h6>
                                    <select className='selectCustomerEditCalendar mb-2' onChange={handleStatus} required title="This field is required" >
                                        <option value="" selected hidden>Ju lutem zgjedhni Rezervuesin </option>
                                        <option disabled>Ju lutem zgjedhni Rezervuesin </option>
                                        {status?.map((el) => {
                                            { console.log("status", status) }
                                            return (
                                                <option
                                                    value={el.ID}
                                                    selected={
                                                        Number(appointmentData?.StatusID) ===
                                                            el?.ID
                                                            ? "selected"
                                                            : false
                                                    }
                                                    className="options">
                                                    {el?.Pershkrimi}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <h6>Rezervuesi</h6>
                                    {userID > 0 ?
                                        <input type="text" name="name" id="name" className="form-control mb-3" defaultValue={appointmentData?.UserFullName} onChange={UserFullName} placeholder="Emri i rezervuesit" disabled /> :
                                        <input type="text" name="name" id="name" className="form-control mb-3" defaultValue={appointmentData?.UserFullName} onChange={UserFullName} placeholder="Emri i rezervuesit" />}
                                    <h6>Pershkrimi i Terminit</h6>
                                    <input type="text" name="name" id="name" className="form-control mb-3" required placeholder="Pershkrim per terminin" defaultValue={appointmentData?.Pershkrimi} onChange={Pershkrimi} />
                                    <h6>Data e Terminit</h6>
                                    <input type="date" name="name" id="name" className="form-control col-12 mb-3" onChange={toDate} defaultValue={dataStartdata} />
                                    <h6>Orari i terminit</h6>
                                    <div className="row mx-auto">
                                        <TimePicker
                                            className="modal-ant-picker form-control col-12 col-lg-4 mb-3 mr-2"
                                            defaultValue={moment(`${dataStartdatahr}`, format)}
                                            format={format}
                                            popupStyle={{ zIndex: "999999" }}
                                            popupClassName={"selectDate"}
                                            showSecond={false}
                                            onChange={(e, i) => toTime(i)}
                                            minuteStep={5}
                                        />
                                        <TimePicker
                                            className="modal-ant-picker form-control col-12 col-lg-4 mb-3 mr-2"
                                            format={format}
                                            popupStyle={{ zIndex: "999999" }}
                                            popupClassName={"selectDate"}
                                            defaultValue={moment(`${dataendhr}`, format)}
                                            onChange={(e, i) => toEnd(i)}
                                            minuteStep={5}
                                        />
                                        <div className="col-12 col-lg-3 ">
                                            <strong type="submit"
                                                className="text-danger mt-2 " onClick={() => setOpenModal(true)} >
                                                Fshije
                                                <i className="fa fa-trash ml-3 "></i>
                                            </strong>
                                            {
                                                deletModal ?
                                                    <>
                                                        <div className="wholePage" onClick={() => setOpenModal(false)}></div>
                                                        <div className='deleteModalAppointment pt-3 pb-5'>
                                                            <div className='deleteModal-wrapper'>
                                                                <h5 className="text-danger text-center">A jeni te sigurt qe deshironi ta fshini kete termin</h5>
                                                                <div className="deleteContent p-0 mt-5">
                                                                    <div className="row">
                                                                        <div className="col-12 col-lg-6">
                                                                            <button className="col-12 button_delete_Po btn btn-danger" onClick={deleteAppointment}>Po</button>
                                                                        </div>
                                                                        <div className=" col-12 col-lg-6">
                                                                            <button className=" col-12 button_delete_Jo btn btn-primary" onClick={() => setOpenModal(false)}>Jo
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit"
                                            disabled={!disabled}
                                            className="button_Edit_Calendar btn "  >
                                            Ruaj
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    } else return null
}
export default EditCalendar;