import axios from "../../../axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "./modalAddCalendarPlus.scss";
// import "./shtoKartelen.scss"
import Close from '../../../images/light-close.svg'
import moment from "moment";
import { connect } from 'react-redux';
import {
    loggMeIN,
    rmAuth,
    toggleMenu,
    setCustomerId

} from "./../../../redux/Functions/actions";
import { useNavigate, useParams } from "react-router";
import { TimePicker } from "antd";
import "antd/dist/antd.css";
import ModalPortal from "../../../portal/ModalPortal";



const AddCalendarAppointment = ({ doctorId, dataWeek, costumerId, getData, getDataRole, getweekCall, user_id, role, dataToAddWithPlus, isAddWithPlusOpen, handleAddPlusClose }) => {
    // console.log('po hapna', props);
    // const [isOpen, setOpen] = useState(true);
    const [isEdit, setEdit] = useState(false);
    const [isdelete, setDelete] = useState(false);
    const [image, setImage] = useState();
    const [isImageEdit, setImageEdit] = useState(false);
    const [data, setData] = useState();
    const [addClinic, setAddClinic] = useState([]);
    const [doctors, setDoctors] = useState()
    const [timeStart, setTimeStart] = useState();
    const [timeEnd, setTimeEnd] = useState();
    const [dataStart, setDatastart] = useState(new Date().toISOString().slice(0, 10));
    const [dataEmd, setDataEnd] = useState(new Date().toISOString().slice(0, 10));
    const [userData, setUserData] = useState();
    const [userIdCalendar, setUserIdCalendar] = useState(0);
    const [docName, setDocName] = useState(doctorId);
    const [search, setSearch] = useState(false);
    const [showLiveSearch, setLiveSearch] = useState();
    const [name, setName] = useState(null);
    const [surname, setSurname] = useState(null);
    const [email, setEmail] = useState(null);

    const format = "HH:mm";

    let dataStartdata = dataStart;
    let dataEnd = dataEmd;
    let kosova = dataStartdata + "T" + timeStart + ":00.000Z"
    let shqip = dataEnd + "T" + timeEnd + ":00.000Z"
    console.log("timeEnd",dataEnd + "T" + timeEnd + ":00.000Z")

    let today = new Date().toISOString().split('T')[0];
  
    useEffect(() => {
        setTimeout(() => {
            const ud = Object.assign({}, userData);
            ud.fromDate = kosova
            ud.toDate = shqip;
            setUserData(ud);
            setDocName(doctorId)
        }, 1000);
    }, [dataStart, dataEmd, timeStart, timeEnd])
    const toDate = (e) => {
        const ud = Object.assign({}, userData);
        setDatastart(e.target.value)
        setDataEnd(e.target.value)
        setUserData(ud);
    };
    const timeEndHandle = (e) => {
        setTimeEnd(e)

    };
    const timeStartHandle = (e) => {
        setTimeStart(e)
    };
    const Pershkrimi = (e) => {
        const ud = Object.assign({}, userData);
        ud.Pershkrimi = e.target.value;
        setUserData(ud);
    };
    const UserFullName = (e) => {
        const ud = Object.assign({}, userData);
        ud.UserFullName = e.target.value;
        setUserData(ud);
    };

    const handleDocName = (e) => {

        setDocName(e.target.value)
    };
    console.log("docName", docName)
    const getDoctors = () => {
        if (role === 10) {
            axios
                .get(`/customer/appointments/getDoctorsByCustomer/${role}`)

                .then((data) => {
                    setDoctors(data?.data);
                })
                .catch((err) => console.log(err));
        }
        if (role === 2) {
            axios
                .get(`/customerUser/appointments/getDoctorsByCustomer/${role}`)

                .then((data) => {
                    setDoctors(data?.data);
                })
                .catch((err) => console.log(err));
        }
        if (role === 3) {
            axios
                .get(`/receptionist/appointments/getDoctorsByCustomer/${role}`)

                .then((data) => {
                    setDoctors(data?.data);
                })
                .catch((err) => console.log(err));
        }

    }


    const handleName = (e) => {
        if (e.target.value === "") {
            setName(null);
        } else {
            setName(e.target.value)
        }

    };
    const handleSurname = (e) => {
        if (e.target.value === "") {
            setSurname(null);
        } else {
            setSurname(e.target.value)
        }
    };
    const handleEmail = (e) => {
        if (e.target.value === "") {
            setEmail(null);
        } else {
            setEmail(e.target.value)
        }
    };
    const handlePatient = (e) => {
        setUserIdCalendar(e.target.value);
    };
    useEffect(() => {
        getDoctors();
    }, [])
    const handleSearch = () => {
        setSearch(true);
        if (role === 10) {
            axios.get(`customer/appointments/findCostumerClients/${name}/${surname}/${email}`).then(
                data => {
                    console.log('appointments', data.data);
                    setLiveSearch(data.data);
                }
            ).catch(err => { console.log('Errori', err) });
        }
        else if (role === 2) {
            axios.get(`customerUser/appointments/findCostumerClients/${name}/${surname}/${email}`).then(
                data => {
                    console.log('appointments', data.data);
                    setLiveSearch(data.data);
                }
            ).catch(err => { console.log('Errori', err) });
        }
        if (role === 3) {
            axios.get(`receptionist/appointments/findCostumerClients/${name}/${surname}/${email}`).then(
                data => {
                    console.log('appointments', data.data);
                    setLiveSearch(data.data);
                }
            ).catch(err => { console.log('Errori', err) });
        }

    }

    const submitData = (e) => {
        e.preventDefault();
        if (role === 10) {
            axios
                .post(`customer/appointments/doctor/${docName}/${userIdCalendar}`, { ...userData, currentUser: user_id })
                .then((res) => {
                    console.log("res", res)
                    handleAddPlusClose();
                    if (res?.data?.response[0]?.inserted_id === 0) {
                        alert(res?.data?.response[0]?.Porosia);
                    }
                    else {
                        if (dataWeek) {

                            getweekCall(docName, dataWeek);
                        } else {
                            getData(docName);

                        }
                    }
                })
                .catch((err) => {
                    console.log("axiosErr", err);
                });
        }
        else if (role === 2) {
            axios
                .post(`customerUser/appointments/doctor/${user_id}/${userIdCalendar}`, { ...userData, currentUser: user_id })
                .then((res) => {
                    console.log("res", res)
                    if (res?.data?.response[0]?.inserted_id === 0) {
                        alert(res?.data?.response[0]?.Porosia);
                    }
                    else {
                        if (dataWeek) {

                            getweekCall(docName, dataWeek);
                        } else {
                            getData(docName);

                        }
                    }
                    handleAddPlusClose();
                })
                .catch((err) => {
                    console.log("axiosErr", err);
                });
        }
        else if (role === 3) {
            axios
                .post(`receptionist/appointments/doctor/${docName}/${userIdCalendar}`, { ...userData, currentUser: user_id })
                .then((res) => {
                    if (res?.data?.response[0]?.inserted_id === 0) {
                        alert(res?.data?.response[0]?.Porosia);
                    }
                    else {
                        console.log("res", res)
                        if (dataWeek) {

                            getweekCall(docName, dataWeek);
                        } else {
                            getData(docName);

                        }
                    }
                    handleAddPlusClose();
                })
                .catch((err) => {
                    console.log("axiosErr", err);
                });
        }
    }

    if (isAddWithPlusOpen) {
        return (
            <ModalPortal wrapperId="AddWithPlusCalendarModal">
                <div className="wholePage" onClick={() => handleAddPlusClose()}></div>
                <div class="modalAddCalendarPlus">
                    <img src={Close} onClick={() => handleAddPlusClose()} className='closeModal ' />
                    <div className='addModalPlusCalendar'>
                        <div className="p-0 mt-5">
                            <form onSubmit={submitData} >
                                <div className="form-input">
                                    <p className="text-left"></p>
                                    {role === 2 ? "" :
                                        <select className='selectCustomerModalPlus mb-2' onChange={handleDocName} required title="This field is required" >
                                            <option value="" selected hidden>Ju lutem zgjedhni </option>
                                            <option disabled>Ju lutem zgjedhni </option>
                                            {doctors?.map((el) => {
                                                return (
                                                    <option
                                                        value={el.id}
                                                        selected={(
                                                            Number(doctorId) ===
                                                            el?.id && (role === 2 || role === 10))
                                                            ? "selected"
                                                            : false
                                                        } className="options">
                                                        {el?.docName}
                                                    </option>
                                                );
                                            })}
                                        </select>}
                                    {/* <h6>Rezervuesi</h6>
                                    <input type="text" name="name" id="name" className="form-control mb-3" onChange={UserFullName} placeholder="Emri i rezervuesit" /> */}
                                    <h6 className="text-left">Rezervuesi</h6>
                                    <input type="text" name="name" id="name" className="form-control mb-3  mr-4" onChange={UserFullName} placeholder="Emri i rezervuesit" />
                                    <h6 className="text-left">Kerko Rezervuesin: </h6>
                                    <div className="row rezervuesi">
                                        <div className="col-lg-4 col-xl-3 col-md-12 col-12 rezervuesiCol">
                                            <input
                                                type="text"
                                                className="main-search form-control mb-3"
                                                placeholder="Emri"
                                                onChange={handleName}

                                            />
                                        </div>
                                        <div className="col-lg-4 col-xl-4 col-md-12 col-12 rezervuesiCol">
                                            <input
                                                type="text"
                                                className="main-search form-control mb-3"
                                                placeholder="Mbiemri"
                                                onChange={handleSurname}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-xl-4 col-md-12 col-12 rezervuesiCol">
                                            <input
                                                type="text"
                                                className="main-search form-control mb-3"
                                                placeholder="Email"
                                                onChange={handleEmail}

                                            />
                                        </div>
                                        <div className="col-md-1 col-12">
                                            <h6 onClick={handleSearch} className="btn searchButton ">Kerko</h6>
                                        </div>
                                    </div>
                                    <select className={search === true ? 'selectCustomerModalPlus mb-2' : 'selectCustomerModalPlus  mb-2 d-none'} onChange={handlePatient} title="This field is required" >
                                        <option value="" selected hidden>Ju lutem zgjedhni Rezervuesin </option>
                                        <option disabled>Ju lutem zgjedhni Rezervuesin </option>
                                        {showLiveSearch?.map((el) => {
                                            return (
                                                <option
                                                    value={el?.id}
                                                    className="options">
                                                    {el?.UserFullName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <h6 className="text-left">Pershkrimi i Terminit</h6>
                                    <input type="text" name="name" id="name" className="form-control mb-3" required placeholder="Pershkrim per terminin" onChange={Pershkrimi} />
                                    <h6 className="text-left">Data e Terminit</h6>
                                    <input type="date" name="name" id="today" className="form-control mb-3" onChange={toDate} defaultValue={dataStartdata} min={today} />
                                    <h6 className="text-left">Orari i terminit</h6>
                                    <div className="row mx-auto">
                                        <TimePicker
                                            className="modal-ant-picker form-control col-12 col-lg-4 mb-3 mr-2"
                                            format={format}
                                            popupStyle={{ zIndex: "999999" }}
                                            popupClassName={"selectDate"}
                                            onChange={(e, i) => timeStartHandle(i)}
                                            minuteStep={5}
                                        />
                                        <TimePicker
                                            className="modal-ant-picker form-control col-12 col-lg-4 mb-3 mr-2"
                                            format={format}
                                            popupStyle={{ zIndex: "999999" }}
                                            popupClassName={"selectDate"}

                                            onChange={(e, i) => timeEndHandle(i)}
                                            minuteStep={5}
                                        />
                                    </div>
                                </div>
                                <button type="submit" className="buttoni-Plus-Calendar" >
                                    Ruaj
                                </button>
                            </form>

                        </div>
                    </div></div>
            </ModalPortal>
        )
    } else return null

}

export default AddCalendarAppointment;